// import Vue from "vue";
// import VueRouter from "vue-router";

// Vue.use(VueRouter);

export default [
  {
    path: "/brandDevelop",
    component: () => import("@/views/aboutUs/brandDevelop2.vue"),
    meta: {
      title: "麦默通 - 品牌之路",
      footTitle: "如果您需要了解Mammotome的更多信息",
    },
  },
  {
    path: "/history",
    component: () => import("@/views/aboutUs/historyPage.vue"),
    meta: {
      title: "麦默通 - 里程碑",
      footTitle: "如果您需要了解Mammotome的更多信息",
    },
  },
  // {
  //   path: "/mission2",
  //   component: () => import("@/views/aboutUs/missionPage.vue"),
  //   meta: {
  //     title: "使命与愿景",
  //     footTitle: "如果您需要了解Mammotome的更多信息",
  //   },
  // },
  {
    path: "/mission",
    component: () => import("@/views/aboutUs/missionPage2.vue"),
    meta: {
      title: "麦默通 - 使命与愿景",
      footTitle: "如果您需要了解Mammotome的更多信息",
    },
  },
  {
    path: "/bility",
    component: () => import("@/views/aboutUs/responsibilityPage.vue"),
    meta: {
      title: "麦默通 - 社会与责任",
      footTitle: "如果您需要了解Mammotome的更多信息",
    },
  },

  {
    path: "/bilityText1",
    component: () => import("@/views/aboutUs/children/bilityText1.vue"),
    meta: {
      title: "麦默通 - 宣教义诊活动",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
  {
    path: "/bilityText2",
    component: () => import("@/views/aboutUs/children/bilityText2.vue"),
    meta: {
      title: "麦默通 - 粉红丝带月活动",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
  {
    path: "/trends", //trendsText1
    component: () => import("@/views/aboutUs/trendsPage.vue"),
    meta: {
      title: "麦默通 - 企业动态",
      footTitle: "如果您需要了解Mammotome的更多信息",
    },
  },
  {
    path: "/trendsText1", //粉红丝带月活动开展
    component: () => import("@/components/trends/trendsText1.vue"),
    meta: {
      title: "麦默通 - 粉红丝带月",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
  {
    path: "/trendsText2", //日间手术
    component: () => import("@/components/trends/trendsText2.vue"),
    meta: {
      title: "麦默通 - 日间手术",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
  {
    path: "/trendsText3", //一麦相承
    component: () => import("@/components/trends/trendsText3.vue"),
    meta: {
      title: "麦默通 - 一麦相承",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
  // 2023新闻
  {
    path: "/trends2023_1", //日间手术
    component: () => import("@/components/trends/2023/trendsText1.vue"),
    meta: {
      title: "麦默通 - 日间手术",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
  {
    path: "/trends2023_2", //日间手术
    component: () => import("@/components/trends/2023/trendsText2.vue"),
    meta: {
      title: "麦默通 - 日间手术",
      footTitle: "如果您需要了解产品和解决方案相关的更多信息",
    },
  },
];
