<!-- 回到顶部 -->
<template>
  <div class="gotop">
    <div class="top" v-show="showBtn" @click="backTop">Top</div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showBtn: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 回到顶部
    showbtn() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 500) {
        this.showBtn = true;
      } else {
        this.showBtn = false;
      }
    },

    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 10);
    },
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
};
</script>
<style lang="less">
@media screen and (min-width: 768px) {
  .gotop {
    .top {
      height: 55px;
      width: 55px;
      position: fixed;
      right: 4%;
      bottom: 28%;
      text-align: center;
      line-height: 35px;
      font-size: 20px;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0px 1px 3px 1px #888888;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 5px 5px 10px rgba(41, 26, 204, 0.12);
    }
  }
}
@media screen and (max-width: 768px) {
  .gotop {
    display: none;
    .top {
      height: 11.667vw;
      width: 11.667vw;
      position: fixed;
      right: 4%;
      bottom: 11%;
      text-align: center;
      line-height: 9.333vw;
      font-size: 5.333vw;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 0vw 0.267vw 0.8vw 0.267vw #888888;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 1.333vw 1.333vw 2.667vw rgba(41, 26, 204, 0.12);
    }
  }
}
</style>