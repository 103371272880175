<!-- 底部 -->
<template>
  <div class="foot" v-if="showfoot">
    <div
      class="foot_top"
      v-if="isActive"
      :class="{ active1: isactive1 == false }"
      :style="{ backgroundColor: bgcolor }"
    >
      <h2>{{ footTitle }}</h2>
      <div class="btn" @click="gocontactUs" style="cursor: pointer">
        联系我们
      </div>
    </div>
    <div class="content">
      <!-- 丹纳赫logo -->
      <div class="dnh">
        <a href="https://www.danaher.com/">
          <img src="@/assets/dnh.jpg" alt="" />
        </a>
      </div>
      <div class="poa">
        <div class="left">
          <div class="items" v-for="(item, index) in footdata" :key="index">
            <h2>{{ item.title }}</h2>
            <p
              @click="goPage(item2)"
              v-for="(item2, index2) in item.child"
              :key="index2"
            >
              {{ item2.text }}
            </p>
          </div>
          <div class="items">
            <h2>关注我们</h2>
            <!-- <p><i></i>&nbsp;400-669-1766</p> -->
            <div class="icon">
              <img src="@/assets/weixin.svg" alt="" />
              <p style="cursor: default" class="wxName">麦默通乳腺健康</p>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="items">
            <h2 style="cursor: auto">中国总部</h2>
            <p style="cursor: default">设备销售：400-669-1766</p>
            <p style="cursor: default">服务支持：400-820-5183</p>
            <p style="cursor: default">邮编：200335</p>
            <p style="cursor: default">
              传真：<span style="margin: 0">021</span>-8031-6380
            </p>
            <p style="cursor: default">
              地址：上海市长宁区福泉北路518号2座301室
            </p>
          </div>
        </div>
      </div>
      <div class="Phone_poa">
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-submenu index="1">
            <template slot="title">
              <span>公司</span>
            </template>
            <el-menu-item-group>
              <el-menu-item @click="phoneGOPage('/brandDevelop')" index="1-1"
                >品牌之路</el-menu-item
              >
              <el-menu-item @click="phoneGOPage('/bility')" index="1-2"
                >社会责任</el-menu-item
              >
              <el-menu-item @click="phoneGOPage('/trends')" index="1-3"
                >企业动态</el-menu-item
              >
              <el-menu-item @click="phoneGOPage('/recruitJob')" index="1-4"
                >热招职位</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span>资源</span>
            </template>
            <el-menu-item-group>
              <el-menu-item @click="phoneGOPage('zhibo')" index="1-1"
                >直播中心</el-menu-item
              >
              <el-menu-item @click="phoneGOPage('/caseStudy')" index="1-2"
                >案例研究</el-menu-item
              >
              <el-menu-item @click="phoneGOPage('/operation')" index="1-3"
                >手术应用</el-menu-item
              >
              <el-menu-item @click="phoneGOPage('/colorPage')" index="1-4"
                >产品彩页</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span>法规</span>
            </template>
            <el-menu-item-group>
              <el-menu-item @click="phoneGOPage('caiye')" index="1-1"
                >隐私政策</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <span>关注我们</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1"
                ><div><img src="@/assets/weixin.svg" alt="" /></div>
                麦默通乳腺健康</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
        <div class="line"></div>
        <div class="headquarter">
          <h2>中国总部</h2>
          <p>设备销售：400-669-1766 &nbsp; &nbsp; 服务支持：400-820-5183</p>
          <p>邮编：200335 &nbsp; &nbsp; 传真：21-80316380</p>
          <p>地址：上海市长宁区福泉北路518号2座301室</p>
        </div>
        <!-- 备案 -->
        <div class="filings" style="cursor: default">
          <p>
            ©️2023 泰维康医疗器械（上海）有限公司<br /><span @click="goICP"
              >沪ICP备17053676号-3</span
            >
          </p>
        </div>
      </div>
    </div>
    <p class="pour">
      ©️2023 泰维康医疗器械（上海）有限公司 <br />
      <span @click="goICP">沪ICP备17053676号-3</span>
    </p>
    <p></p>

    <!-- 隐私协议 -->
    <div class="agreement" v-if="showagreement">
      <div class="close" @click="showagreement = false">
        <i class="el-icon-error"></i>
      </div>
      <div class="content">
        <h2>泰维康隐私政策</h2>
        <div class="text">
          <div>
            <p>生效日期：【2023】年【8】月【11】日</p>
            <h3>关于本政策</h3>
            <p>
              泰维康医疗器械（上海）有限公司（统称“泰维康”或“我们”）重视您的隐私和个人数据的保护。本《隐私政策》解释了我们如何处理在网站、移动网站、移动应用程序和其他数字服务和产品上，以及在由泰维康或以泰维康身份开展业务的任何关联公司线下控制且链接到本《隐私政策》的网站、移动网站、移动应用程序和其他数字服务和产品（
              统称为“泰维康网站”）上向我们提供的个人数据。
              泰维康是根据适用法律处理个人数据的数据控制者。若使用本网站，即表示您同意泰维康处理您的数据，并同意我们的隐私政策条款。<br />
            </p>
            <p>
              本《隐私政策》不适用于未链接到本《隐私政策》的泰维康网站，或者泰维康网站可能链接的第三方网站。
            </p>
            <h3>我们收集的信息类型</h3>
            <p>
              本部分描述了我们在您使用泰维康网站时可能收集的个人数据类型，包括您提供给我们的信息和我们在您使用泰维康网站时自动收集的信息，以及您可以如何通过行使适用的法律权利来管理此类处理。
              就本《隐私政策》而言，“个人数据”（或也称为“个人信息”）是指可识别您的身份或可合理用于识别您的身份的信息。个人数据的示例包括姓名、地址、电话号码和电子邮件地址。
              在某些情况下，您可能会获得与本政策背离的特定情况隐私声明，例如招聘等特定活动的隐私声明。如果您收到了不同的声明，则这些声明适用并监管我们与您的互动。如果您提供关于您以外的各方的个人数据，您有责任确保他们了解我们将如何处理他们的个人数据，并在适用的情况下提前获得所有必要的许可。
              我们致力于根据适用法律处理个人数据。请注意，如果您不希望向我们提供您的个人数据，
              您可能无法使用某些泰维康网站上的服务。若您使用泰维康网站，即表明您已获悉并同意我们在适用法律允许的范围内按照本政策所述来收集、使用、传输和披露您的信息。<br />
            </p>
            <h3>您提供的信息</h3>
            <p>
              您无需注册服务或程序即可收到通过泰维康网站提供的大部分信息。但是，我们的某些内容仅供注册用户或经过身份验证的用户使用，并且需要您设置个人资料或提供有关您自己的特定信息，才能为您提供服务。<br />
            </p>
            <h3>您在访问泰维康网站时提供的个人数据</h3>
            <p>
              您可能会在购买产品、接收营销产品和信息、联系泰维康客户服务或回答泰维康问卷或调查时提供个人数据。此类数据包括：<br />
              1.联系信息，例如您的姓名、地址、电话号码或电子邮件地址<br />
              2.注册信息，例如您的用户名和密码<br />
              3.当您询问泰维康的工作时提供的就业、教育和其他背景信息<br />
              4.您可能提供的内容（例如，当您填写我们的在线联系表或提交其他信息时）。<br />
            </p>
            <h3>您与泰维康进行其他交往沟通时分享的个人数据</h3>
            <p>
              您可以联系泰维康提出问题、讨论您的疑虑或报告关于我们产品的问题。如果您与泰维康沟通或请求泰维康提供信息，系统可能会提示您提供您的联系信息以及与您的请求相关的任何个人数据。这些信息包括：姓名、邮寄地址、电话号码、电子邮件地址、用户名和密码。
            </p>
            <h3>从其他来源收集的信息</h3>
            <p>
              出于本声明中指定的目的，我们可能会将来自泰维康网站关于您的信息与您离线提供的信息相结合。
              我们将按照通知要求和其他适用法律处理我们从您或其他人离线获取的任何个人数据。
            </p>
            <h3>我们如何使用您的信息</h3>
            <p>
              我们使用您的个人数据为您提供您请求的产品和服务、与您进行通信沟通、改善您在泰维康网站上的体验、全面改进我们的产品和服务以及用于其他内部业务目的，包括检测安全事件、调试以识别和修复损害现有功能的错误、代表我们执行服务或您请求的服务（例如，提供网上营销和广告服务）以及完成交易。这些用途还可包括个人数据的记录、组织、整理、存储、改编或更改、检索、咨询、使用、通过传输、传播或以其他方式公开、对齐或组合、限制、删除或销毁。我们收集的关于您的个人数据将仅在出于上述目的并根据适用法律合理必要的情况下保留，直到实现个人数据的收集目的或达到适用法律规定的期限，以时间较长者为准。当不再需要保留您的个人数据时，我们将进行删除或匿名化数据。
            </p>
            <h3>提供您要求的产品和服务</h3>
            <p>
              如果您选择购买产品或接受我们的服务，我们将使用您通过泰维康网站提供的个人数据来管理您的订单和发票、处理付款、回答您的问题、为您提供您请求的服务以及提供最佳客户体验。
            </p>
            <h3>营销</h3>
            <p>
              您还可能会收到来自泰维康和相关关联公司的营销信息，例如关于泰维康相关产品或服务的优惠信息、参加我们产品调查的邀请或关于特别促销的通知。在这些情况下，我们将使用您的联系方式和其他个人数据向您发送营销信息。
            </p>
            <h3>业务和产品改进</h3>
            <p>
              为了发现可以帮助泰维康更好地了解客户需求并帮助改进、开发和评估产品、服务、材料和程序的新事实，泰维康将分析您提供的信息。对于这些目的，泰维康不会使用可以直接识别您身份的信息。
            </p>
            <h3>网站分析和改进</h3>
            <p>
              我们可能会使用您提供的信息以及我们自动收集的关于您使用泰维康网站的信息来监控用户流量模式和偏好，以改进、分析和优化网站。
            </p>
            <h3>进行处理的法律依据</h3>
            <p>
              根据适用法律，泰维康必须有法律依据才能处理您的个人数据。在某种情况下适用的法律依据将取决于泰维康处理您的个人数据的上述具体目的：<br />
              •
              在某些情况下，如果适用法律要求，泰维康可能会征求您的同意以收集和处理您的个人数据。如果您选择给予同意，您以后可以按照“您的隐私选择”部分所述方式联系我们，以撤回您的同意。请注意，撤回同意不会影响已经发生的处理。<br />
              •
              在其他情况下，为了遵守适用的法律或法规或为了履行对您适用的合同，可能有必要处理您的个人数据。您可能无法选择退出此处理，或者您选择退出可能会影响我们履行对您的合同义务的能力。<br />
              •
              在其他情况下，泰维康可能会基于泰维康与您就我们的产品和服务以及科学研究和教育机会进行沟通的合法利益，而处理您的个人数据。您有权选择退出对您个人数据的所有此类处理。
              您可以按照“您的隐私选择”部分中所述的方式联系我们。
            </p>

            <h3>我们共享的信息</h3>
            <p>
              我们可能出于以下商业目的与第三方共享关于您使用泰维康网站的信息：<br />
              第三方广告和网上行为广告<br />
            </p>
            <p>
              您可能会在为您量身定制的第三方网站和移动服务上收到泰维康产品和服务的在线广告，例如，根据您提供给泰维康或您正在访问的第三方网站的信息，或关于您的浏览活动、购买或兴趣的信息。这些类型的定制在线广告可能来自多个来源，例如：
              <br />
              •
              有些泰维康网站参与在线行为广告（有时也称为重新定位广告或基于兴趣的广告）。您可以通过页脚中的“广告选择”链接来识别这些网站。当向您投放泰维康在线行为广告时，您会看到一个“广告选择”图标。点击该图标或链接会将您带到一个网站，您可以在该网站上管理或选择不使用关于您浏览历史的数据，此类数据用于投放在线行为广告。如果您选择退出，您可能仍会看到在线广告，包括来自泰维康的基于其他信息（例如，基于正在查看的页面内容而不是您之前的点击流活动）的广告。在某些情况下，这些第三方广告商可能仍会收集关于您的浏览活动的数据，但他们不会使用这些数据来投放基于您过去的在线浏览行为的广告。<br />
              •
              有些网络浏览器可能会向浏览器与之通信的网站发送“禁止跟踪”信号。截至本《隐私政策》生效之日，尚未就如何响应这些信号制定行业标准。因此，泰维康目前不响应这些信号。如上所述，您可以访问广告选择，以选择退出使用您的浏览历史投放在线行为广告的广告商。<br />
            </p>
            <p>
              您在使用本网站期间，本网站将持续为您保存您的个人信息。在您注销本网站账户后，本网站仅在法律法规规定、监管要求的期限内，以及为实现本《隐私保护条款》声明的目的所必须的时限内保留您的个人信息。超出上述个人信息保存期限后，本网站会对您的个人信息进行删除或匿名化处理。
            </p>

            <h3>关联公司、供应商和提供商</h3>
            <p style="text-indent: 0em">
              • 我们与关联公司共享适用法律规定的个人数据<br />
              • 我们出于商业目的与服务提供商和渠道合作伙伴共享个人数据。<br />
              联合品牌网站<br />
            </p>
            <p>
              我们可能会与其他公司合作，以联合或“联合品牌”方式为您提供内容或服务。在联合品牌网站上，您会在屏幕上看到泰维康徽标和联合品牌合作伙伴的徽标。
              您应该阅读我们的联合品牌合作伙伴的个人隐私政策，因为这些个人隐私政策在某些方面可能与我们的政策有所区别。阅读这些政策将帮助您就是否向特定网站提供您的信息做出明智的决定。<br />
            </p>
            <p style="text-indent: 0em">产品报告</p>
            <p>
              关于您使用我们产品的体验，如果您联系泰维康，我们可能会根据法律要求，使用您提供的信息向指定的政府监管机构提交报告。
            </p>

            <p style="text-indent: 0em">法律权利和义务<br /></p>
            <p>
              在某些有限的情况下，泰维康可能需要披露您的个人数据，以履行法律义务或要求，例如，为确保我们产品的安全性或者为了出售或转让我们的一个产品线或部门（包括通过一个或多个泰维康网站提供的服务），向我们的监管主管部门履行申报义务。在此类情况下，我们将采取措施尽可能保护您的个人数据。我们还有权使用个人数据调查和起诉违反我们的规则、从事非法活动或危害他人或他人财产的用户。<br />
            </p>
            <p style="text-indent: 0em">组织变更<br /></p>
            <p>
              如果泰维康决定通过出售、合并或收购等方式重组或剥离我们的业务，泰维康可能会向实际或潜在买家提供关于您的个人数据。
              我们将要求任何实际或潜在买家以符合本政策的方式处理此类个人数据。<br />
            </p>
            <p style="text-indent: 0em">儿童隐私<br /></p>
            <p>
              泰维康不会故意直接从泰维康网站上的儿童（我们将“儿童”定义为 18
              岁以下的未成年人）收集或使用任何个人数据。我们不会故意允许儿童订购我们的产品、与我们通信交流或使用我们的任何在线服务。如果您是父母并且意识到您的孩子向我们提供了信息，请使用以下指定的方法之一与我们联系，我们将与您一起解决此问题。
            </p>
            <h3>您的隐私选择和权利</h3>
            <p>
              您有权了解我们的数据处理惯常做法，以及我们与之共享您的个人数据的实体、您拒绝给予同意的权利以及拒绝同意的后果。
              您还有权查看和获取我们维护的关于您的个人数据的副本，有权要求我们更正关于您的不准确或不完整的个人数据。
              您有权以机器可读格式接收您提供给我们的数据，并将此类数据传输给另一个控制者。您还有权要求删除您的个人数据或限制其处理，或反对处理您的个人数据。除上述权利外，我们将尊重您根据适用法律可能拥有的任何其他权利。<br />
              根据适用法律，您还有权要求了解我们收集的个人数据类别、我们获取个人数据的来源、我们收集数据的原因以及我们与谁共享这些数据。<br />
            </p>
            <p>
              如需访问关于您的个人数据、行使任何其他权利、提出疑虑、投诉或要求更正，或选择退出特定计划，请点击您正在使用的泰维康网站上的“联系我们”链接，或通过您正在使用的泰维康移动应用程序上的泰维康联系信息，或通过此网络表单提交请求，以联系我们的隐私办公室。
              您也可以致函以下地址：
            </p>
            <p style="text-indent: 0em">
              【公司名称】泰维康医疗器械（上海）有限公司
            </p>
            <p style="text-indent: 0em">
              【公司地址】中国上海市区长宁区福泉北路518号2座3楼
            </p>
            <p style="text-indent: 0em">
              【公司电邮】MMTCHINA.Marketing@mammotome.com
            </p>
            <p>
              许多国家设有数据保护机构，以确保遵守数据保护法。关于您的隐私权的更多信息，或者如果您无法直接与我们一起解决问题并希望进行投诉，请联系您当地的主管部门。
            </p>

            <p>
              在与泰维康的所有通信中，请提供您的电子邮件地址、网站地址、移动应用程序和/或您向其提供个人数据的特定泰维康产品（例如
              mammotome.cn），以及关于您的请求的详细说明。如果您要删除、修改或更正您的个人数据并通过电子邮件与我们联系，请在电子邮件的主题行中注明“删除请求”或“修改/更正请求”。我们会及时回应所有合理的请求，并可能需要进一步确认您的身份，以便处理某些请求。
            </p>

            <p>
              收到您的请求后，我们可能需要与您联系以进一步核实相关详情。根据适用法律，您可能有权通过授权代理人提交请求。
              要指定授权代理人代表您行使您的权利和做出选择，请提供充分的授权证明。
            </p>
            <h3>数据安全</h3>
            <p>
              泰维康实施合理的技术、管理和物理控制措施，以保护通过泰维康网站收集的任何个人数据。但是，始终存在一些风险，未经授权的第三方可能会截获互联网传输，或者有人会想方设法破坏我们的安全系统。我们敦促您在通过互联网传输个人数据时谨慎行事，尤其是您的财务相关信息。泰维康无法保证未经授权的第三方不会访问您的个人数据；
              因此，在向泰维康网站提交个人数据时，您必须权衡利弊。
            </p>
            <h3>第三方网站和社交媒体插件</h3>
            <p>
              本《隐私政策》不适用于未链接到本《隐私政策》的泰维康网站，或者泰维康网站可能链接的第三方网站。泰维康网站可能会使用社交媒体插件，以使您能够轻松地与他人分享信息。当您访问泰维康网站时，社交媒体插件的运营商可以在您的计算机或其他电子设备上放置一个
              cookie，以使该运营商能够识别之前访问过我们网站或其他网站或服务的个人，以及接收您访问过我们的泰维康网站的相关信息。社交媒体插件还允许社交媒体网站与其社交媒体网站的其他用户共享关于您在我们泰维康网站上的活动的信息。这些共享设置由社交媒体网站管理，并受其隐私政策约束。
            </p>

            <h3>在全球范围内访问我们的网站</h3>
            <p>
              本网站由泰维康拥有并且在中国运营，但是我们在其他国家的关联公司、供应商和提供商可以根据本政策访问您提供的信息。此外，如果您从中国以外的国家访问本网站，您与我们的通信交流可能会导致信息跨境国际传输。所有国家对个人数据的法律保护水平各不相同；
              但是，我们将采取本《隐私政策》中所述的安全措施，以确保您的信息安全。通过使用本网站，您理解，您的个人数据将在中国以及我们在业务运营过程中可能将您的信息传输到的任何国家存储和处理。
            </p>

            <p>
              在符合适用法律规定的前提下，泰维康可能会将您的个人数据传输给全球各地的相关关联公司。
              这些关联公司可能会将您的个人数据传输给其他相关关联公司。
              有些关联公司可能位于无法确保足够数据保护水平的国家。
              尽管如此，泰维康的所有相关关联公司必须以符合本政策的方式处理个人数据。只有当我们确信已根据适用的数据保护法律保护您正在处理的个人数据的完整性和安全性时，我们才会将您的个人数据进行国际传输或传输到第三方。
              如有必要（包括向关联公司传输），我们将使用欧盟批准的《标准合同条款》提供此类保护。
              要获取关于我们为跨境传输个人数据制定的保护措施的更多信息，请使用“您的隐私选择”部分中提供的信息与我们联系。
            </p>
            <h3>我们隐私政策的修改</h3>
            <p>
              我们仅按照本《隐私政策》中所述的，向您收集信息或者您授权收集信息时有效的方式使用个人数据。但是，在遵守任何适用的同意要求之前提下，我们有权随时修改本《隐私政策》的条款。对本《隐私政策》的任何修改将反映在该页面上，并注明新的生效日期。泰维康鼓励您定期查看本《隐私政策》以了解任何修改。在您继续使用泰维康网站时收集的任何个人数据将根据当时发布的《隐私政策》进行处理。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showagreement: false,
      showfoot: true,
      footdata: [
        {
          title: "公司",
          child: [
            {
              text: "品牌之路",
              path: "brandDevelop",
            },
            {
              text: "社会责任",
              path: "bility",
            },
            {
              text: "企业动态",
              path: "trends",
            },
            {
              text: "热招职位",
              path: "recruitJob",
            },
          ],
        },
        {
          title: "资源",
          child: [
            {
              text: "直播中心",
              path: "zhibo",
            },
            {
              text: "案例研究",
              path: "caseStudy",
            },
            {
              text: "手术应用",
              path: "operation",
            },
            {
              text: "产品彩页",
              path: "colorPage",
            },
          ],
        },
        {
          title: "法规",
          child: [
            {
              text: "隐私政策",
              path: "zanwu",
            },
          ],
        },
      ],
      isActive: false,
      isactive1: true,
      footTitle: "",
      value1: 0,
      option1: [
        { text: "全部商品", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      bgcolor: "fff",
      screenWidth: document.body.clientWidth,
    };
  },
  computed: {},
  watch: {
    $route: {
      handler(newval) {
        // console.log(newval);
        if (
          newval.path == "/lixuan-lp" ||
          newval.path == "/ruiyan-lp" ||
          newval.path == "/ruixuan-lp"
        ) {
          this.showfoot = false;
        } else {
          this.showfoot = true;
        }
        if (
          newval.path == "/contactUs" ||
          newval.path == "/home" ||
          newval.path == "/mission" ||
          newval.path == "/mission2" ||
          newval.path == "/colorPage"
        ) {
          this.isActive = false;
        } else {
          this.isActive = true;
        }
        this.footTitle = newval.meta.footTitle;
        if (newval.path == "/aboutUs") {
          // console.log(111111);
          this.isactive1 = false;
        } else {
          this.isactive1 = true;
        }
        // console.log(this.footTitle);
        if (this.screenWidth <= 768) {
          if (
            newval.path == "/product" ||
            newval.fullPath == "/goodsdetail?id=10" ||
            newval.fullPath == "/activity" ||
            newval.fullPath == "/recruit" ||
            newval.fullPath == "/recruitJob" ||
            newval.path == "/jobDetail" ||
            newval.path == "/trends" ||
            newval.path == "/detection"
          ) {
            this.bgcolor = "#f5f5f5";
          }
          if (newval.path == "/aboutUs") {
            this.bgcolor = "#FAFAFA ";
          }
          if (newval.path == "/brandDevelop") {
            this.bgcolor = "rgb(252, 252, 252)";
          }
          // console.log(newval.fullPath, "foot");

          if (
            newval.path == "/goodsPage" ||
            newval.path == "/productdetail" ||
            newval.path == "/activity1" ||
            newval.path == "/activity3" ||
            newval.path == "/activity2" ||
            newval.path == "/operation" ||
            newval.path == "/healthCare"
          ) {
            this.bgcolor = "#fff";
          }

          if (
            newval.path == "/ultrasonic" ||
            newval.path == "/molyb" ||
            newval.path == "/resonance" ||
            newval.fullPath == "/goodsdetail?id=11" ||
            newval.path == "/focus" ||
            newval.path == "/guide" ||
            newval.path == "/college"
          ) {
            this.bgcolor = "#F8F8F8";
          }
          if (
            newval.fullPath == "/productdetail?id=7" ||
            newval.path == "/scene" ||
            newval.fullPath == "/productdetail?id=4" ||
            newval.fullPath == "/productdetail?id=5" ||
            newval.path == "/guideText1" ||
            newval.path == "/caseStudy"
          ) {
            // console.log(111);
            this.bgcolor = "#f5f5f5";
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    goICP() {
      window.open("https://beian.miit.gov.cn");
    },
    handleOpen(key, keyPath) {
      key, keyPath;
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      key, keyPath;
      // console.log(key, keyPath);
    },
    phoneGOPage(item) {
      // console.log(item);
      if (item == "zhibo") {
        window.open(
          "https://wx.vzan.com/live/pc/index?liveId=2100458963&shareuid=0"
        );
      } else if (item == "caiye") {
        this.showagreement = true;
        // console.log(1);
      } else {
        this.$router.push({
          path: item,
        });
      }
    },
    goPage(item) {
      if (item.path == "zhibo") {
        window.open(
          "https://wx.vzan.com/live/pc/index?liveId=2100458963&shareuid=0"
        );
      }
      if (item.path == "zanwu") {
        this.showagreement = true;
      }

      if (item.path != "zanwu" && item.path != "zhibo") {
        this.$router.push({
          path: "/" + item.path,
        });
      }
    },
    gocontactUs() {
      this.$router.push({
        path: "/contactUs",
      });
      window.screenTop = 0;
    },
  },
  created() {},
  mounted() {
    // console.log(this.$route);
    if (this.$route.fullPath == "/aboutUs") {
      // console.log(111);
      this.isactive1 = false;
    } else {
      this.isactive1 = true;
    }

    //
    // var fn = [];
    // const a = [1, 1, 1, 1, 1];
    // for (let i = 0; i < 5; i++) {
    //   for (let j = 0; j < i; j++) {
    //     a[i] = a[i] + a[j];
    //   }
    // }
    // for (let i = 0; i < 5; i++) {
    //   console.log(a[i], "ai");
    // }
    const arr = ["name", "first", "5", 7, 4, "2", 9];
    var arr1 = [];
    for (var i = 0; i < arr.length; i++) {
      arr1.push(arr.slice(-i - 1)[0]);
    }
    // console.log(arr1);
  },
};
</script>

<style lang="less" scoped>
// pc
@media screen and (min-width: 768px) {
  .active {
    position: absolute;
    bottom: 0;
  }
  .pr {
    position: relative;
  }
  .dnh {
    width: 6.5rem;
    height: 6rem;
    position: absolute;
    bottom: 8.5%;
    right: 0%;

    img {
      width: 100%;
    }
  }
  .active1 {
    width: 100%;
    height: 70rem;
    background: url("@/assets/foot2.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 55.2rem;
    background-color: #fcfcfc !important;

    h2 {
      margin-top: 38rem;
      font-size: 2.4rem;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      // font-weight: bold;
      color: #ffffff;
    }
    .btn {
      margin-top: 3rem;
      width: 15.78rem;
      height: 4.44rem;
      background: #628ec9;
      border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
      opacity: 1;
      line-height: 4.44rem;
      text-align: center;

      font-size: 2rem;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  .foot_top {
    width: 100%;
    height: 69.5rem;
    background: url("@/assets/foot2.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 43.2rem;
    // background-color: rgba(0, 0, 0, 0) !important;

    h2 {
      margin-top: 38rem;
      font-size: 2.4rem;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .btn {
      margin-top: 3rem;
      width: 15.78rem;
      height: 4.44rem;
      background: #628ec9;
      border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
      opacity: 1;
      line-height: 4.44rem;
      text-align: center;

      font-size: 2rem;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
  }
  .foot {
    // width: 100%;
    // height: 59rem;
    // background: url("@/assets/foot.png") no-repeat;
    // background-size: 100% 100%;
    position: relative;
    .content {
      width: 120rem;
      height: 47.7rem;

      margin: 0 auto;
      position: relative;
      .Phone_poa {
        display: none;
      }
      .poa {
        width: 100%;
        position: absolute;
        top: 9.9rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        justify-content: space-between;
      }
      // width: 100%;
      color: #84329b;

      .left {
        // width: 50%;
        display: flex;
        justify-content: space-between;
        h2 {
          // font-size: 1.8rem;
          margin-bottom: 2rem;
          font-size: 1.8rem;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #494d52;
        }
        p {
          // font-size: 1.4rem;
          margin-bottom: 1.5rem;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #84329b;
          // line-height: 28px;
        }
        p:hover {
          cursor: pointer;
        }
        .items {
          margin-right: 10rem;
          i {
            display: inline-block;
            width: 1rem;
            height: 1rem;
            background: #84329b;
            border-radius: 50%;
          }
          .icon {
            width: 2.3rem;
            height: 2.3rem;
            position: relative;

            img {
              width: 100%;
            }
          }
        }
        .wxName {
          transition: 0.3s;
          position: absolute;
          top: 50%;
          margin-bottom: 0;
          transform: translateY(-50%);
          right: -11rem;

          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;

          color: #84329b;
        }
      }
      .right {
        // width: 36.2rem;
        .items {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          position: relative;
          h2 {
            font-size: 1.8rem;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #494d52;
            margin-bottom: 2rem;
          }
          p {
            font-size: 1.4rem;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #84329b;
            margin-bottom: 1rem;
          }
          p:hover {
            cursor: pointer;
          }
        }
      }
    }
    .pour {
      width: 120rem;
      font-size: 1.2rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #75787b;
      position: absolute;
      bottom: 1.9%;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
      span:hover {
        transition: 0.3s;
        color: #4187e2;
      }
    }
  }
  .agreement {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    .close {
      position: absolute;
      top: 5rem;
      right: 10rem;
      font-size: 2.73vw;
    }
    .content {
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #75787b;
      font-size: 2rem;

      .text {
        height: 100%;
        overflow-y: scroll;
        p {
          text-indent: 2em;
          margin-bottom: 0;
        }
        h3 {
          display: inline-block;
          font-weight: bold;
          color: rgba(0, 0, 0, 0.85);
          margin: 2rem 0;
        }
      }
      h2 {
        text-align: center;
        font-size: 2vw;
        font-weight: bold;
      }
    }
  }
}
// phone
@media screen and (max-width: 768px) {
  .dnh {
    width: 18vw;
    // height: 7.8vw;
    position: absolute;
    bottom: 3%;
    right: 50%;
    transform: translateX(50%);

    img {
      width: 100%;
    }
  }
  /deep/.el-message {
    min-width: 100vw !important;
    box-sizing: border-box;
    border-radius: 0.21vw;
    border-width: 1px;
    border-style: solid;
    border-color: #ebeef5;
    position: fixed;
    left: 50%;
    top: 1.04vw;
    transform: translateX(-50%);
    background-color: #edf2fc;
    transition: opacity 0.3s, transform 0.4s, top 0.4s;
    overflow: hidden;
    padding: 0.78vw 0.78vw 0.78vw 1.04vw;
    display: flex;
    align-items: center;
  }
  .active {
    position: absolute;
    bottom: 0;
  }
  .pr {
    position: relative;
  }
  // .active1 {
  //   width: 100%;
  //   height: 70rem;
  //   background: url("@/assets/Aphone/foot.png") no-repeat;
  //   background-size: 100% 100%;
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  //   align-items: center;
  //   position: absolute;
  //   bottom: 55.2rem;
  //   background-color: #fcfcfc;

  //   h2 {
  //     margin-top: 38rem;
  //     font-size: 2.4rem;
  //     font-family: Microsoft YaHei-Bold, Microsoft YaHei;
  //     // font-weight: bold;
  //     color: #ffffff;
  //   }
  //   .btn {
  //     margin-top: 3rem;
  //     width: 15.78rem;
  //     height: 4.44rem;
  //     background: #628ec9;
  //     border-radius: 0.4rem 0.4rem 0.4rem 0.4rem;
  //     opacity: 1;
  //     line-height: 4.44rem;
  //     text-align: center;

  //     font-size: 2rem;
  //     font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  //     font-weight: 400;
  //     color: #ffffff;
  //   }
  // }
  .foot {
    margin-top: -0.2vw;
    position: relative;
    .foot_top {
      display: block;
      width: 100%;
      height: 45.6vw;
      background: url("@/assets/foot2.png") no-repeat;
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      // background-color: rgba(0, 0, 0, 0) !important;
      // background-color: #fcf;

      h2 {
        margin-top: 20vw;
        font-size: 2.667vw;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      .btn {
        margin-top: 3rem;
        width: 18.4vw;
        height: 4.267vw;
        background: #628ec9;
        border-radius: 0.267vw 0.267vw 0.267vw 0.267vw;

        line-height: 4.267vw;
        text-align: center;

        font-size: 2.667vw;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
    }
    .content {
      width: 100vw;
      // height: 20vw;
      display: block;
      margin: 0 auto 0;
      // position: absolute;
      background-color: #fff;
      padding: 0 5.867vw 0 5.867vw;
      box-sizing: border-box;
      // bottom: 0;
      .Phone_poa {
        padding: 0 0 27vw 0;
        box-sizing: border-box;
        /deep/.el-submenu__title:hover {
          background-color: #ffffff !important;
        }
        /deep/.el-menu {
          border-right: solid 0px #e6e6e6;
        }
        /deep/.el-submenu__title {
          padding-left: 0 !important;
          font-size: 3.733vw;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #000000;
          height: 5.92vw !important;
          line-height: 5.92vw !important;
          margin-top: 3.533vw;
        }
        /deep/.el-menu--inline {
          // margin-top: -2vw;
        }
        /deep/.el-menu-item-group__title {
          padding-left: 5.333vw;
          padding: 0.36vw 0 0.36vw 1.04vw;
          line-height: normal;
          font-size: 3.733vw;
          color: #909399;
        }
        /deep/ .el-submenu .el-menu-item {
          height: 5.6vw;
          line-height: 5.6vw;
          padding: 0 2.34vw;
          min-width: 10.42vw;
          font-size: 3.733vw;
          padding-left: 5.667vw !important;
          margin-bottom: 2vw;
        }
        .el-dropdown-menu__item,
        .el-menu-item {
          padding-left: 0 !important;
          height: 5.92vw !important;
          line-height: 5.92vw !important;
          font-size: 3.733vw;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #000000;
        }
        /deep/.el-submenu__icon-arrow {
          position: absolute;
          top: 26%;
          // transform: translateY(-50%);
          right: 1.04vw;
          margin-top: -0.36vw;
          transition: transform 0.3s;
          font-size: 3.733vw;
        }
        .line {
          margin-top: 10.667vw;
          width: 100%;
          height: 0.8vw;
          background-color: #e9eaeb;
          border-radius: 13vw;
        }
        .headquarter {
          margin-top: 4.8vw;
          h2 {
            text-align: center;
            font-size: 3.733vw;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
          }
          p {
            text-align: center;
            font-size: 3.2vw;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #84329b;
            margin-bottom: 0.5vw;
          }
        }
        .filings {
          margin-top: 2vw;
          p {
            text-align: center;
            font-size: 2.667vw;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #75787b;
          }
        }
      }
      .poa {
        display: none;
        width: 100%;
      }
      // width: 100%;
      color: #84329b;
    }
    .pour {
      display: none;
      width: 120rem;
      font-size: 1.2rem;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #75787b;
      position: absolute;
      bottom: 1.9%;
      left: 50%;
      transform: translateX(-50%);
      text-align: left;
    }
  }
  .agreement {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 9999;
    .close {
      position: absolute;
      top: 11rem;
      right: 11rem;
      font-size: 5.73vw;
    }
    .content {
      height: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #75787b;
      font-size: 7rem;
      .text {
        height: 100%;
        overflow-y: scroll;
      }
      h2 {
        text-align: center;
        font-size: 5vw;
        font-weight: bold;
      }
    }
  }
}
</style>